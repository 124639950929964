import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Airport, AirportOption, AirportsBulkUpdate } from './airports.model';
import { HttpClient } from '@angular/common/http';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { tap } from 'rxjs/operators';
import { SeasonCode } from '@shared/models/seasons.model';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class AirportsHttpService {
  private rootEndPoint = 'airport';
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getAllAirports(season: SeasonCode): Observable<Airport[]> {
    const now = Math.floor(Date.now() / 1000);
    return this.http.get<Airport[]>(`${this.apiUrl}/${season}/${this.rootEndPoint}?time=${now}`).pipe(
      tap((airports: Airport[]) =>
        airports.forEach((airport: Airport) => {
          Airport.updateCountry(airport);
        })
      )
    );
  }

  getAirportOptions(season: SeasonCode): Observable<AirportOption[]> {
    const now = Math.floor(Date.now() / 1000);
    return this.http.get<AirportOption[]>(`${this.apiUrl}/${season}/${this.rootEndPoint}/options?time=${now}`);
  }

  addAirport(airport: Airport, season: SeasonCode): Observable<Airport> {
    return this.http.post<Airport>(`${this.apiUrl}/${season}/${this.rootEndPoint}`, airport);
  }

  updateAirport(airport: Airport, code: string, season: SeasonCode): Observable<Airport> {
    return this.http.put<Airport>(`${this.apiUrl}/${season}/${this.rootEndPoint}/${code}`, airport);
  }

  bulkUpdateAirports(airports: AirportsBulkUpdate, season: SeasonCode): Observable<Airport[]> {
    return this.http.patch<Airport[]>(`${this.apiUrl}/${season}/${this.rootEndPoint}/bulk-update`, airports);
  }

  deleteAirport(code: string, season: SeasonCode): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${season}/${this.rootEndPoint}/${code}`);
  }
}
