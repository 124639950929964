import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';
import { ScheduleInfo } from '../schedules.models';
import { ScheduleUploadAdvancedSettings } from '../schedule-upload-file/schedule-upload-file.component';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class SchedulesMainHttpService {
  private rootEndPoint = 'schedule-info';
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getAllSchedules(seasonCode: SeasonCode): Observable<ScheduleInfo[]> {
    return this.http.get<ScheduleInfo[]>(`${this.apiUrl}/${seasonCode}/${this.rootEndPoint}`);
  }

  updateSchedule(scheduleInfo: ScheduleInfo, seasonCode: SeasonCode): Observable<ScheduleInfo> {
    return this.http.put<ScheduleInfo>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleInfo.id}`,
      scheduleInfo
    );
  }

  updateSchedulePrivacy(scheduleInfo: ScheduleInfo, seasonCode: SeasonCode, is_private: boolean): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleInfo.id}/privacy?is_private=${is_private}`,
      null
    );
  }

  deleteSchedule(id: number, seasonCode: SeasonCode): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${id}`);
  }

  cloneSchedule(id: number, name: string, seasonCode: SeasonCode): Observable<ScheduleInfo> {
    return this.http.put<ScheduleInfo>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/clone/${id}?name=${encodeURIComponent(name)}`,
      null
    );
  }

  uploadSSIM(
    file: File,
    season: SeasonCode,
    params: ScheduleUploadAdvancedSettings
  ): Observable<{ schedule_id: number; season: string }> {
    const formData: FormData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
    formData.append('file', file);
    return this.http.post<{ schedule_id: number; season: string }>(`${this.apiUrl}/${season}/ssim/import`, formData);
  }
}
