import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';
import { ScheduleSettingsCap } from './schedule-settings.models';
import { ScheduleLinearity, ScheduleSectorCaps } from '@features/schedules/flying-lines/flying-lines.model';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class SchedulesSettingsHttpService {
  private rootEndPoint = 'schedule';
  private apiUrl: string;

  constructor(private http: HttpClient) {}

  getScheduleSectorCaps(scheduleId: number, seasonCode: SeasonCode): Observable<ScheduleSectorCaps[]> {
    return this.http.get<ScheduleSectorCaps[]>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/sectorcaps`
    );
  }

  getScheduleLinearity(scheduleId: number, seasonCode: SeasonCode): Observable<ScheduleLinearity[]> {
    return this.http.get<ScheduleLinearity[]>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/linearity`
    );
  }

  updateScheduleLinearity(
    scheduleId: number,
    seasonCode: SeasonCode,
    scheduleLinearity: ScheduleLinearity
  ): Observable<ScheduleLinearity> {
    return this.http.put<ScheduleLinearity>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/linearity`,
      scheduleLinearity
    );
  }

  addScheduleSectorCap(
    scheduleId: number,
    seasonCode: SeasonCode,
    scheduleSettingsCap: ScheduleSettingsCap
  ): Observable<ScheduleSettingsCap> {
    return this.http.post<ScheduleSettingsCap>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/sectorcaps`,
      scheduleSettingsCap
    );
  }

  deleteScheduleSectorCap(scheduleId: number, seasonCode: SeasonCode, sectorCapId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/sectorcaps/${sectorCapId}`
    );
  }

  deleteAllScheduleSectorCaps(scheduleId: number, seasonCode: SeasonCode): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/sectorcaps`);
  }

  updateScheduleSectorCap(
    scheduleId: number,
    sectorCapId: string,
    seasonCode: SeasonCode,
    updateInfo: ScheduleSettingsCap
  ): Observable<ScheduleSettingsCap> {
    return this.http.put<ScheduleSettingsCap>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/sectorcaps/${sectorCapId}`,
      updateInfo
    );
  }

  getScheduledDays(scheduleId: number, seasonCode: SeasonCode, marketId: number): Observable<number[]> {
    return this.http.get<number[]>(
      `${this.apiUrl}/${seasonCode}/${this.rootEndPoint}/${scheduleId}/market/${marketId}/optimiser/scheduled`
    );
  }
}
